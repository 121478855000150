var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"form-box"},_vm._l((_vm.initData.information),function(item,index){return _c('b-col',{key:index,class:{
      'my-1': item.type != null && item.text != null
    },attrs:{"md":item.md || 6}},[(item.type != null && item.show != false)?_c('b-form-group',{staticClass:"search-flex",class:[{ required: item.required }, item.class],attrs:{"label":item.text,"id":item.id,"label-for":'form-' + index,"label-cols-sm":"4","label-cols-lg":"3"}},[(item.type == 'input')?[_c('b-input-group',{class:{ no_r: !item.icon },scopedSlots:_vm._u([{key:"append",fn:function(){return [(item.icon || item.icon_text || item.icon_color)?_c('b-input-group-text',[(item.icon)?_c('b-icon',{attrs:{"icon":item.icon}}):_vm._e(),_vm._v(" "+_vm._s(item.icon_text)+" "),(item.icon_color && item.icon_color.indexOf('#') >= 0)?_c('div',{staticClass:"color-box",style:('background:' + item.icon_color)}):_vm._e(),(item.icon_color && item.icon_color.indexOf('#') < 0)?_c('div',{staticClass:"color-box",style:(("background:url(" + (item.icon_color) + ");background-size: 100%;"))}):_vm._e()],1):_vm._e()]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":item.input_type || 'text',"min":item.min,"max":item.max,"placeholder":item.disabled
                ? ''
                : item.placeholder || _vm.$t('page.please_choose_input'),"disabled":item.disabled},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1)]:(item.type == 'text')?[_c('div',{class:item.text_class},[_vm._v(" "+_vm._s(item.display_text)+" ")])]:(item.type == 'select')?[(item.options == null || item.options == 0)?_c('SelectLoading',{attrs:{"size":0.7}}):_vm._e(),_c('sv-select',{attrs:{"disabled":item.options == null || item.options == 0 || item.disabled,"placeholder":item.disabled ? '' : item.placeholder || _vm.$t('page.please_choose'),"options":item.options,"reduce":function (options) { return options.value; },"multiple":item.multiple,"selectable":function (options) { return !options.selectable; },"label":"label"},scopedSlots:_vm._u([{key:"option",fn:function(option){return (option.index)?[_c('div',{staticClass:"d-center",class:'level_' + option.index},[(option.index == 2)?_c('span',[_vm._v("--")]):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")])]:undefined}}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})]:_vm._e(),(item.type == 'textarea')?[_c('b-form-textarea',{attrs:{"id":item.id,"rows":item.rows,"placeholder":item.disabled
              ? ''
              : item.placeholder || _vm.$t('page.please_choose_textarea'),"disabled":item.disabled},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})]:(item.type == 'date')?[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(!item.disabled && item.model != null && item.model != '')?_c('div',{staticClass:"datepicker-x",on:{"click":function($event){return _vm.initialTime(item, 'model')}}},[_c('b-icon-x',{attrs:{"font-scale":"1.45"}})],1):_vm._e(),_c('b-input-group-text',[_c('b-icon-calendar2-date')],1)]},proxy:true}],null,true)},[_c('b-form-datepicker',{attrs:{"date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            },"hide-header":true,"placeholder":item.disabled ? '' : item.placeholder,"disabled":item.disabled,"locale":"de"},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1)]:(item.type == 'radio')?[_c('b-form-radio-group',{attrs:{"id":item.id,"options":item.options,"name":'radio-' + index,"text-field":"label"},on:{"change":function($event){return item.change($event)}},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})]:_vm._e(),(item.type == 'view')?[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return item.click()}}},[_vm._v("View PDF ")])]:_vm._e()],2):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }