<template>
  <b-row class="form-box">
    <b-col
      :md="item.md || 6"
      v-for="(item, index) in initData.information"
      :key="index"
      :class="{
        'my-1': item.type != null && item.text != null
      }"
    >
      <b-form-group
        v-if="item.type != null && item.show != false"
        :label="item.text"
        :id="item.id"
        :label-for="'form-' + index"
        class="search-flex"
        v-bind:class="[{ required: item.required }, item.class]"
        label-cols-sm="4"
        label-cols-lg="3"
      >
        <!-- input -->
        <template v-if="item.type == 'input'">
          <b-input-group :class="{ no_r: !item.icon }">
            <template v-slot:append>
              <b-input-group-text
                v-if="item.icon || item.icon_text || item.icon_color"
              >
                <b-icon v-if="item.icon" :icon="item.icon"></b-icon>
                {{ item.icon_text }}
                <div
                  v-if="item.icon_color && item.icon_color.indexOf('#') >= 0"
                  class="color-box"
                  :style="'background:' + item.icon_color"
                ></div>
                <div
                  v-if="item.icon_color && item.icon_color.indexOf('#') < 0"
                  class="color-box"
                  :style="
                    `background:url(${item.icon_color});background-size: 100%;`
                  "
                ></div>
              </b-input-group-text>
            </template>

            <b-form-input
              v-model="item.model"
              :type="item.input_type || 'text'"
              :min="item.min"
              :max="item.max"
              :placeholder="
                item.disabled
                  ? ''
                  : item.placeholder || $t('page.please_choose_input')
              "
              :disabled="item.disabled"
            ></b-form-input>
          </b-input-group>
        </template>
        <!-- 纯文字 -->
        <template v-else-if="item.type == 'text'">
          <div :class="item.text_class">
            {{ item.display_text }}
          </div>
        </template>
        <!-- 下拉框 -->
        <template v-else-if="item.type == 'select'">
          <SelectLoading
            v-if="item.options == null || item.options == 0"
            :size="0.7"
          ></SelectLoading>
          <sv-select
            v-model="item.model"
            :disabled="
              item.options == null || item.options == 0 || item.disabled
            "
            :placeholder="
              item.disabled ? '' : item.placeholder || $t('page.please_choose')
            "
            :options="item.options"
            :reduce="options => options.value"
            :multiple="item.multiple"
            :selectable="options => !options.selectable"
            label="label"
          >
            <template slot="option" slot-scope="option" v-if="option.index">
              <div class="d-center" :class="'level_' + option.index">
                <span v-if="option.index == 2">--</span>
                {{ option.label }}
              </div>
            </template>
          </sv-select>
        </template>
        <!-- 文本框 -->
        <template v-if="item.type == 'textarea'">
          <b-form-textarea
            :id="item.id"
            :rows="item.rows"
            :placeholder="
              item.disabled
                ? ''
                : item.placeholder || $t('page.please_choose_textarea')
            "
            :disabled="item.disabled"
            v-model="item.model"
          ></b-form-textarea>
        </template>
        <!-- 时间组件-->
        <template v-else-if="item.type == 'date'">
          <b-input-group>
            <template v-slot:append>
              <div
                v-if="!item.disabled && item.model != null && item.model != ''"
                class="datepicker-x"
                @click="initialTime(item, 'model')"
              >
                <b-icon-x font-scale="1.45"></b-icon-x>
              </div>
              <b-input-group-text>
                <b-icon-calendar2-date></b-icon-calendar2-date>
              </b-input-group-text>
            </template>
            <b-form-datepicker
              v-model="item.model"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              :hide-header="true"
              :placeholder="item.disabled ? '' : item.placeholder"
              :disabled="item.disabled"
              locale="de"
            ></b-form-datepicker>
          </b-input-group>
        </template>
        <!-- 单选框 -->
        <template v-else-if="item.type == 'radio'">
          <b-form-radio-group
            v-model="item.model"
            :id="item.id"
            :options="item.options"
            :name="'radio-' + index"
            @change="item.change($event)"
            text-field="label"
          ></b-form-radio-group>
        </template>
        <template v-if="item.type == 'view'">
          <b-button variant="primary" @click="item.click()">View PDF </b-button>
        </template>
      </b-form-group>
    </b-col>
  </b-row>

  <!-- <Others></Others> -->
</template>

<script>
import SelectLoading from '@/components/Loading/Index';

export default {
  name: 'uploadInvoice',

  data() {
    return {
      id: null
    };
  },
  props: {
    initData: Object,
    options: Object,
    formError: Boolean
  },
  inheritAttrs: false,
  components: {
    SelectLoading
    // PurchaseOverview
    // MerchantPartner
    // Others
  },
  methods: {
    init() {
      // this.initData();
    },
    initialTime(obj, name) {
      console.log(obj, name);
      obj[name] = null;
    }
  },

  computed: {},
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
